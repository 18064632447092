import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
  }
`;

export const Container = styled.div`
  font-family: "Montserrat Alternates", sans-serif;
  line-height: 1.6;

  p {
    margin: 10px 0;
    font-size: 15px;
    font-weight: 400;
  }

  b {
    color: #333;
  }

  a {
    color: #007bff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  ol {
    list-style-type: decimal;
    padding-left: 20px;
  }
`;
